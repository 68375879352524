import { useEffect, useRef, useState } from "react";
import { Dialog } from "@mui/material";
import BlockInformationSection from "./BlockInformationSection/BlockInformationSection";
import ShowroomInformationSection from "./ShowroomInformationSection/ShowroomInformationSection";
import BlockReasonSection from "./BlockReasonSection/BlockReasonSection";
import Button from "../../Button/Button";
import { formatTime, formatDate, createNewDates } from "../../../utils/formatDateTime";
import "./createBlockShowroomModal.css";
import fetchClient from "../../../services/fetch";
import { useDispatch } from "react-redux";
import { getBlockedShowroomsList, toggleNotification } from "../../../redux-sagas/actions";
import dayjs from "dayjs";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const CreateBlockShowroomModal = ({
	mode,
	calendarSlot,
	closeModal,
	setRowSelectionModel,
	setSelectedMultiple,
	open,
	buildQuery,
	selectedMultiple
}) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const shouldSubmit = useRef(false);
	const [payload, setPayload] = useState({
		showroomIds: mode === "create" ? [] : selectedMultiple.map((item) => item.showroomId),
		startDate: calendarSlot ? dayjs(calendarSlot.start) : null,
		endDate: calendarSlot ? dayjs(calendarSlot.start) : null,
		startTime: calendarSlot ? dayjs(calendarSlot.start) : null,
		endTime: calendarSlot ? dayjs(calendarSlot.end) : null
	});
	const [errors, setErrors] = useState({});

	useEffect(() => {
		if (mode === "edit") {
			fetchBlockData();
		}
	}, []);

	useEffect(() => {
		if (!Object.keys(errors).length && shouldSubmit.current) sendRequest();
	}, [errors]);

	const fetchBlockData = () =>
		fetchClient()
			.get(`/blockedshowrooms/${selectedMultiple[0].scheduleId}`)
			.then((res) => {
				const data = res.data.data;
				setPayload({ ...createNewDates(data), showroomIds: [data.showroomId] });
			})
			.catch((err) => console.log(err));

	const handleChangeDropdown = (e) =>
		setPayload({
			...payload,
			[e.target.name]: e.target.name === "showroomIds" ? [e.target.value] : e.target.value
		});

	const handleChangeCheckbox = (e) => {
		const { checked, name } = e.target;
		const newObj = { ...payload, [name]: checked };

		if (checked) {
			newObj.startTime = newObj.endTime = null;
		}

		setPayload(newObj);
	};

	const handleChangeDateTime = (newValue, name) => setPayload({ ...payload, [name]: newValue });

	const validate = () => {
		const obj = {};
		if (!payload.startDate) obj.startDate = true;
		if (!payload.endDate) obj.endDate = true;
		if (!payload.isAllDay) {
			if (!payload.startTime) obj.startTime = true;
			if (!payload.endTime) obj.endTime = true;
		}
		if (!payload.showroomIds.length) obj.showroomIds = true;
		if (!payload.reason) obj.reason = true;

		setErrors(obj);
	};

	const sendRequest = () => {
		const obj = {
			...payload,
			startDate: formatDate(payload.startDate),
			endDate: formatDate(payload.endDate)
		};

		if (obj.startTime && obj.endTime) {
			obj.startTime = formatTime(payload.startTime);
			obj.endTime = formatTime(payload.endTime);
		}

		(mode === "edit" ? fetchClient().put : fetchClient().post)(
			"/blockedshowrooms" + (mode === "edit" ? `/${selectedMultiple[0].scheduleId}` : ""),
			obj
		)
			.then(() => {
				dispatch(getBlockedShowroomsList(buildQuery()));
				dispatch(
					toggleNotification({
						isOpen: true,
						type: "success",
						content1: `${
							mode === "multiple"
								? getTranslation("Multiple blocks have been", t)
								: getTranslation("The block has been", t)
						} ${mode === "edit" ? t("edited_el") : t("created_el")} ${getTranslation(
							"successfully",
							t
						)}.`,
						highlight1: "",
						content2: "",
						highlight2: ""
					})
				);
			})
			.catch((err) =>
				dispatch(
					toggleNotification({
						isOpen: true,
						type: "error",
						content1: getTranslation(err.response.data.message, t),
						highlight1: "",
						content2: "",
						highlight2: ""
					})
				)
			)
			.finally(() => {
				setRowSelectionModel([]);
				setSelectedMultiple([]);
			});
		closeModal();
	};

	const handleSubmitClick = () => {
		validate();
		shouldSubmit.current = true;
	};

	return (
		<Dialog
			className='create-block-showroom'
			open={open}
			onClose={closeModal}
		>
			<h2>
				{mode === "multiple"
					? getTranslation("Multiple blocks", t)
					: getTranslation((mode === "edit" ? "Edit" : "Create") + " block", t)}
			</h2>
			<BlockInformationSection
				payload={payload}
				errors={errors}
				handleChangeDateTime={handleChangeDateTime}
				handleChangeCheckbox={handleChangeCheckbox}
				className={mode === "create" ? "order-0" : "order-1"}
			/>
			<ShowroomInformationSection
				payload={payload}
				errors={errors}
				handleChangeDropdown={handleChangeDropdown}
				className={mode === "create" ? "order-1" : "order-0"}
				mode={mode}
				selectedMultiple={selectedMultiple}
			/>
			<BlockReasonSection
				payload={payload}
				errors={errors}
				handleChangeDropdownCheckbox={handleChangeDropdown}
				className='order-2'
			/>
			<div className='btns order-3'>
				<Button
					onClick={closeModal}
					type='secondary'
				>
					{getTranslation("Cancel", t)}
				</Button>
				<Button
					onClick={handleSubmitClick}
					type='primary'
				>
					{getTranslation("Accept", t)}
				</Button>
			</div>
		</Dialog>
	);
};

export default CreateBlockShowroomModal;
